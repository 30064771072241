import React from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from 'react-grid-system';
import { HeroPatients } from 'components/hero';
import { PageCallout } from 'components/callouts';

import imgIcon from 'images/patient/icons/icon-helpful-info.png';

// import page style
import './savings-program.scss';

const SavingsProgram = () => {
	return (
		<Layout indication='patient' className='patient-savings-program'>
			<Seo pageTitle='Savings Program' />
			<div className='body-content-container'>
				<div className='body-content'>
					<HeroPatients heroImgAlt='page hero' className='sub-page-hero'>
						<h1>
							OxyContin<sup>&reg;</sup> Savings Program
						</h1>
					</HeroPatients>
					<Row>
						<Col xs={12}>
							<PageCallout
								className='green-cta'
								linkUrl='/support-materials/'
								imgIcon={imgIcon}
								ctaTitle='Helpful Information'
								ctaCopy='Materials to help you understand your treatment'
								linkText='Learn More >'
							/>
						</Col>
					</Row>
					<section>
						<Row>
							<Col xs={12}>
								<p>
									Patients with a valid prescription for OxyContin should call
									1-855-227-0303 to speak to a customer service representative
									about signing up for the savings program. Patients must meet
									eligibility requirements. Other restrictions may apply.
								</p>
								<ul className='blue-bullets'>
									<li>
										<span>
											The OxyContin Savings Program can help reduce
											out-of-pocket prescription costs for eligible patients
										</span>
									</li>
									<li>
										<span>
											Patients with questions about the Savings Program should
											call 1‑855‑227‑0303, 8 <span className='small'>AM</span>{' '}
											to 6 <span className='small'>PM</span> ET, Monday through
											Friday
										</span>
									</li>
								</ul>
								<h2 className='primary-headline'>
									<span className='text-bold'>Eligibility Requirements</span>
								</h2>
								<p>
									This offer is valid only for patients with commercial (private
									or non-governmental) insurance. It is not valid for patients
									(i) who are government beneficiaries or whose prescription
									drugs are eligible to be reimbursed, in whole or in part, by
									any Federal Health Care Program, as that term is defined at 42
									U.S.C. §1320a-7b(f), including Medicaid, Medicare, a Medicare
									Part D or Medicare Advantage plan, TRICARE, CHAMPUS, Puerto
									Rico Government Health Insurance Plan, or any other state or
									federal health care program; (ii) who are Medicare-eligible
									and enrolled in an employer-sponsored health plan or
									prescription drug benefit program for retirees; (iii) who are
									cash-paying patients; or (iv) who reside in a state where the
									card is prohibited.
								</p>
								<h2 className='primary-headline'>
									<span className='text-bold'>Terms and Conditions</span>
								</h2>
								<p>
									Patient must meet eligibility requirements. This offer is only
									available with a valid prescription. The offer is not valid
									for medications patient received for free or that are eligible
									to be reimbursed by private insurance plans or any other
									healthcare pharmaceutical assistance programs that reimburse
									patients for the entire cost of their medications. Patient
									agrees not to seek reimbursement for all or any part of the
									benefit received through this offer. Patient agrees to report
									their use of this offer to any third party that reimburses or
									pays for any part of the prescription price. Patient
									additionally agrees that they will not submit any portion of
									the product dispensed pursuant to this card to a federal or
									state healthcare program for purposes of counting it toward
									their out-of-pocket expenses. This offer has no cash value and
									is not valid with any other program, discount, or incentive
									involving the covered medication. Use of this offer must be
									consistent with all relevant health insurance requirements and
									payer agreements. Participating patients, pharmacies,
									physician offices, and hospitals are obligated to inform
									third-party payers about the use of the offer as provided for
									under the applicable insurance or as otherwise required by
									contract or law. This offer may not be sold, purchased,
									traded, or offered for sale, purchase, or trade. This offer is
									limited to one per person during the offering period and is
									not transferable. This offer is not contingent upon any past,
									present, or future purchases of the covered medication or any
									other product, and this offer may be rescinded, revoked, or
									amended without notice. No reproductions. This offer is not
									health insurance or a benefit plan. This offer is void where
									prohibited or where restricted beyond the terms herein.
									Maximum benefits apply. For questions about this offer, call
									1-855-227-0303 (please dial carefully).
								</p>
								<p>
									Purdue Pharma L.P. reserves the right to rescind, revoke, or
									amend this offer without notice at any time.
								</p>
							</Col>
						</Row>
					</section>
				</div>
			</div>
		</Layout>
	);
};

export default SavingsProgram;
